import React, { useState } from "react";
import { alertIconType, buttonStyle, CommonModal } from "./common-modal.component";
import Button from "@cx/ui/Button";
import "./common-modal-test-page.scss";

// Sample URL to launch this test page locally, with the required query params:
// http://localhost:3000/common-modal-test-page?userName=csr.advisor1&webKey=dmspluse2enext3xx1&appType=CSR&appEnv=ua9

// Documentation:
// https://coxautoinc.sharepoint.com/sites/community-xtimewiki/_layouts/15/Doc.aspx?sourcedoc=%7B118c2628-28e0-4325-a437-29925b1a9dcc%7D&action=edit&wd=target%28Reusable+components.one%7C4ca47cdd-ed40-4432-a7f2-dabf89921a82%2FCommon+Modal%7C899a87a8-b9d5-4141-9f52-915a6b31adb9%2F%29&wdorigin=703

// prettier-ignore
const CommonModalTestPage = () => {
  const [visibleModal, setVisibleModal] = useState(null);

  const modals = [
    {
      id: "basic",
      name: "Basic confirmation modal",
      modal:
        <CommonModal
          show={visibleModal === "basic"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          message="Your feedback has been submitted."
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "basicUsingAlert",
      name: "Basic confirmation modal using alert",
      modal:
        <CommonModal
          show={visibleModal === "basicUsingAlert"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          alert={{
            type: alertIconType.success,
            message: "Your feedback has been submitted."
          }}
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "speedBumpModal",
      name: "Typical \"speed bump\" modal, with a danger style button",
      modal:
        <CommonModal
          show={visibleModal === "speedBumpModal"}
          onHide={() => setVisibleModal("")}
          title="Delete user"
          message="Are you sure you want to delete this user?"
          buttons={[
            {
              text: "No",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Yes",
              style: buttonStyle.danger,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "fullFeaturedModal",
      name: "Full-featured modal",
      modal:
        <CommonModal
          show={visibleModal === "fullFeaturedModal"}
          onHide={() => setVisibleModal("")}
          title="Full-featured modal"
          message="This modal has a title, a message, an alert panel, a required Reason input, and OK & Cancel buttons."
          alert={{
            type: alertIconType.warning,
            message: (
              <>
                <p>This a "warning" alert panel.</p>
                <p>Also, it contains JSX elements instead of a simple string.</p>
              </>
            )
          }}
          reasonProps={{
            isRequired: true
          }}
          buttons={[
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "overwriteService",
      name: "Overwrite existing service",
      modal:
        <CommonModal
          show={visibleModal === "overwriteService"}
          onHide={() => setVisibleModal("")}
          title="Overwrite existing service?"
          alert={{
            type: alertIconType.danger,
            message: (
              <>
                <p>This service was already added to this RO, and has tech time started for it.</p>
                <p>
                  If you add this service again, the existing service will be overridden,
                  and the tech time will be lost.
                </p>
              </>
            )
          }}
          buttons={[
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "overwriteServiceModalCancelButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Add service",
              style: buttonStyle.danger,
              testId: "overwriteServiceModalAddServiceButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "cannotVoidRO",
      name: "Cannot void RO",
      modal:
        <CommonModal
          show={visibleModal === "cannotVoidRO"}
          onHide={() => setVisibleModal("")}
          title="Cannot void RO"
          alert={{
            type: alertIconType.danger,
            message: (
              <>
                <p>Cannot void repair order with tech time or approved/committed parts.</p>
                <p>
                  To prepare this RO to be voided, please remove all tech time and
                  approved/committed parts.
                </p>
              </>
            )
          }}
          buttons={[
            {
              text: "Done",
              style: buttonStyle.primary,
              testId: "voidRODeniedModalDoneButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "voidROConfirmation",
      name: "Void RO confirmation",
      modal:
        <CommonModal
          show={visibleModal === "voidROConfirmation"}
          onHide={() => setVisibleModal("")}
          title="Void RO"
          message="Are you sure you want to void this repair order?"
          alert={{
            type: alertIconType.warning,
            message: "This action cannot be undone."
          }}
          reasonProps={{
            isRequired: true
          }}
          buttons={[
            {
              text: "No",
              style: buttonStyle.secondary,
              testId: "voidROConfirmationModalNoButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Yes",
              style: buttonStyle.danger,
              testId: "voidROConfirmationModalYesButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "cannotRemoveService",
      name: "Cannot remove service",
      modal:
        <CommonModal
          show={visibleModal === "cannotRemoveService"}
          onHide={() => setVisibleModal("")}
          title="Cannot remove service"
          alert={{
            type: alertIconType.danger,
            message: (
              <>
                <p>A service cannot be removed if it has any tech time or approved/committed parts.</p>
                <p>
                  To prepare this service to be removed, please
                  remove all tech time and approved/committed parts.
                </p>
              </>
            )
          }}
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "cannotRemoveServiceModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    {
      id: "lastServiceRemoved",
      name: "Last service removed",
      modal:
        <CommonModal
          show={visibleModal === "lastServiceRemoved"}
          onHide={() => setVisibleModal("")}
          title="Void RO?"
          message="You've removed the last service from this RO. Would you like to void the RO?"
          alert={{
            type: alertIconType.warning,
            message: "This action cannot be undone."
          }}
          buttons={[
            {
              text: "No",
              style: buttonStyle.secondary,
              testId: "lastServiceRemovedModalCancelButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Void RO",
              style: buttonStyle.danger,
              testId: "lastServiceRemovedModalVoidROButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    /*
    TEMPLATE:
    {
      id: "_____",
      name: "_______________",
      modal:
        <CommonModal
          show={visibleModal === "___copy_the_id_here___"}
          onHide={() => setVisibleModal("")}
          title="_______________"
          message="_______________"
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
    },
    */
  ];

  const activateModal = modal => {
    setVisibleModal(modal.id);
  };

  const buttons = modals.map(modal => {
    return (
      <Button key={modal.name} htmlId={`${modal.name}Button`} onClick={() => activateModal(modal)}>{modal.name}</Button>
    );
  });

  return (
    <div className="common-modal-test-page">
      <div className="buttons">
        {buttons}
      </div>
      {modals.map(modal => {
        return (
          <div key={modal.name}>
            {modal.modal}
          </div>
        );
      })}
    </div>
  )
};

export default CommonModalTestPage;
