/* eslint-disable unused-imports/no-unused-vars */
import { makeSecureRestApi } from "../../../api/xmmAxios";
import cloneDeep from "lodash/cloneDeep";
import isNumber from "lodash/isNumber";
import { syncPartsPricingData } from "../../page-wrapper/utils/data-util";
import quoteService from "./edit-quote.service";
import { logError } from "../../../services/log.service";
import { generatePartId } from "../../../utils/helper.util";
import { priceSourceLabels } from "../../../constants/pages.constants";

const formatParts = (parts, quoteUserId) => {
  const formattedParts = parts.map(servicePart => {
    // Destructured properties WILL be removed
    const {
      partId: extPartId,
      partName,
      partsPrice,
      alternateParts,
      dmsPending,
      dmsPrice,
      partNumberDefinedByDealerFlag,
      partPriceSource,
      priceSource,
      qualifiers,
      recordType,
      relationship,
      selected,
      specification,
      status,
      quantity,
      unitPrice,
      unitOfMeasure,
      vehicleAttributes,
      ...strippedPart
    } = servicePart;
    const formattedPart = {
      ...strippedPart,
      unitPrice,
      quantity,
      partPrice: unitPrice * quantity,
      extPartId: !extPartId ? generatePartId() : extPartId,
      description: partName,
      partName,
      unitOfMeasure,
      priceSource:
        partPriceSource === priceSourceLabels.MANUAL
          ? partPriceSource
          : priceSource,
      adjustedQuantity: quantity,
      lastModByUserId: quoteUserId,
      dtDmsPartCode: servicePart?.dtDmsPartCode || null,
      manufacturer: servicePart?.manufacturer || "OEM"
    };
    return formattedPart;
  });
  return formattedParts;
};

const updateQuoteFromModifyParts = async ({
  serviceParts,
  totalPartsPrice,
  quoteSummary,
  selectedService,
  quoteUserId
}) => {
  const { confirmationId, dealerCode } = quoteSummary;
  const { extServiceId: selectedServiceId } = selectedService;
  const quoteSummaryCopy = cloneDeep(quoteSummary);
  const nonModifiedQuoteServices = quoteSummaryCopy.quoteServices.filter(
    service => {
      return service.extServiceId !== selectedServiceId;
    }
  );
  const quoteServiceToUpdate = quoteSummaryCopy.quoteServices.find(service => {
    return service.extServiceId === selectedServiceId;
  });
  // @fix: price override cases
  const totalPriceOverridden = isNumber(selectedService.totalPriceOverride);
  const finalTotalPartsPrice = isNumber(
    selectedService?.totalPartsPriceOverride
  )
    ? selectedService?.totalPartsPriceOverride
    : totalPartsPrice;

  const updatedSelectedService = {
    ...quoteServiceToUpdate,
    // @note: For Declined, Recall, Dealer pub, Global repair: Expected String value from service.dealershipNotes field
    dealershipNotes: selectedService?.dealershipNotes
      ? [{ note: selectedService?.dealershipNotes?.trim() }]
      : null,
    parts: formatParts(serviceParts, quoteUserId),
    lastModByUserId: quoteUserId,
    calculatedTotalPartsPrice: totalPartsPrice,
    finalPartsPrice: finalTotalPartsPrice,
    finalLaborPrice: totalPriceOverridden
      ? selectedService.totalPriceOverride - finalTotalPartsPrice
      : selectedService.finalLaborPrice,
    servicePrice: totalPriceOverridden
      ? selectedService.totalPriceOverride
      : finalTotalPartsPrice + quoteServiceToUpdate.finalLaborPrice
  };
  // console.log(
  //   "modify parts/editContext service/ quote summary service",
  //   updatedSelectedService,
  //   quoteServiceToUpdate
  // );
  const newQuoteServices = [];
  newQuoteServices.push(updatedSelectedService);
  nonModifiedQuoteServices.forEach(service => {
    newQuoteServices.push(service);
  });
  // @note: destructured properties above ...strippedQuoteSummary are removed from quoteSummary since they shouldn't be in payload
  // Destructured properties WILL be removed
  const {
    totalPrice,
    totalPartsPrice: _totalPartsPrice,
    message,
    ...strippedQuoteSummary
  } = quoteSummaryCopy;
  const payload = {
    ...strippedQuoteSummary,
    quoteServices: [...newQuoteServices],
    // TODO update totalPrice
    totalPrice: null,
    subtotalPrice: null,
    expirationDays: 30,
    totalPartsPrice: null,
    totalLaborPrice: null
  };
  return await quoteService.updateQuote(payload);
};
// Menus case
const updateQuoteFromModifyPartsWithMenu = ({
  serviceParts,
  totalPartsPrice,
  quoteSummary,
  selectedService,
  quoteUserId,
  menuPackage
}) => {
  const { confirmationId, dealerCode } = quoteSummary;
  const { extServiceId: selectedServiceId } = selectedService;
  const quoteSummaryCopy = cloneDeep(quoteSummary);
  const nonModifiedQuoteServices = [];
  let quoteServiceToUpdate;
  quoteSummaryCopy.quoteServices.forEach(service => {
    if (Number(service.extServiceId) !== Number(menuPackage.extServiceId)) {
      nonModifiedQuoteServices.push(service);
    } else {
      quoteServiceToUpdate = service;
    }
  });
  const menuServiceToUpdate = quoteServiceToUpdate.menuServices.find(
    service => {
      return service.extServiceId === selectedServiceId;
    }
  );
  // Store the value of totalPriceOverride or null if it comes as 0 so that totalPriceOverridden flag below is also false in that case.
  const totalPriceOverrideLocal =
    selectedService.totalPriceOverride === 0
      ? null
      : selectedService.totalPriceOverride;
  const totalPriceOverridden = isNumber(totalPriceOverrideLocal);
  const updatedSelectedMenuService = {
    ...menuServiceToUpdate,
    // @note: For Menu Service case: Expected String value from service.dealershipNotes field
    parts: formatParts(serviceParts, quoteUserId),
    lastModByUserId: quoteUserId,
    calculatedTotalPartsPrice: trimPrice(totalPartsPrice),
    finalPartsPrice: trimPrice(totalPartsPrice),
    finalLaborPrice: totalPriceOverridden
      ? trimPrice(selectedService.totalPriceOverride - totalPartsPrice)
      : trimPrice(selectedService.finalLaborPrice),
    servicePrice: totalPriceOverridden
      ? trimPrice(selectedService.totalPriceOverride)
      : trimPrice(totalPartsPrice + menuServiceToUpdate.finalLaborPrice),
    calculatedServicePrice: trimPrice(
      totalPartsPrice + menuServiceToUpdate.finalLaborPrice
    ),
    // Within the ui, dealershipNotes are handled as string, but should be included in the payload as an object array
    dealershipNotes: selectedService?.dealershipNotes
      ? [{ note: selectedService?.dealershipNotes?.trim() }]
      : null
  };
  const menuRecordCopy = cloneDeep(menuPackage);
  // BUG-FIX -> Delete excess fields added by UI - labors, parts, labor,menuOptions  at menuRecord before sending payload
  delete menuRecordCopy.parts;
  delete menuRecordCopy.labors;
  delete menuRecordCopy.labor;
  delete menuRecordCopy.menuOptions;
  const { ...strippedMenuRecord } = menuRecordCopy;
  const newMenuServices = strippedMenuRecord.menuServices.filter(service => {
    return service.extServiceId !== selectedServiceId;
  });
  newMenuServices.push(updatedSelectedMenuService);
  const updatedFinalPartsPrice = newMenuServices
    .map(service => service.finalPartsPrice)
    .reduce((acc, curr) => acc + curr, 0);
  const packagePriceOverridden = isNumber(
    strippedMenuRecord.totalPriceOverride
  );
  // @note: spread operator is used below to conditinally update servicePrice; if the condition is not met (package is overridden), servicePrice is not updated
  const newMenuRecord = {
    ...strippedMenuRecord,
    menuServices: newMenuServices,
    finalPartsPrice: updatedFinalPartsPrice.toFixed(2),
    ...(!packagePriceOverridden
      ? {
          servicePrice: (
            strippedMenuRecord.finalLaborPrice + updatedFinalPartsPrice
          ).toFixed(2)
        }
      : {})
  };
  const newQuoteServices = [];
  newQuoteServices.push(newMenuRecord);
  nonModifiedQuoteServices.forEach(service => {
    newQuoteServices.push(service);
  });
  const {
    totalTaxes,
    totalPrice,
    totalPartsPrice: _totalPartsPrice,
    lastModDateTime,
    subtotalPrice,
    totalLaborPrice,
    dealerCode: _dealerCode,
    quoteServices,
    ...strippedQuoteSummary
  } = quoteSummaryCopy;

  const payload = {
    ...strippedQuoteSummary,
    quoteServices: [...newQuoteServices],
    // TODO update totalPrice
    totalPrice: null,
    subtotalPrice: null,
    expirationDays: 30,
    totalPartsPrice: null,
    totalLaborPrice: null
  };
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data: payload
      },
      response => {
        syncPartsPricingData(response?.quoteServices);
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

const markPartAsApproved = async (
  appContext,
  quoteSummary,
  quoteServicePartId
) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  const requestPayload = user.id.toString();

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/parts/${quoteServicePartId}/dealercode/${dealerCode}/quote/${confirmationId}/approvePart`,
        method: "post",
        data: requestPayload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to mark parts as approved."
    );
  });
};

const updatePart = async (
  appContext,
  quoteSummary,
  quoteServicePartId,
  typeOfPurchase
) => {
  const { confirmationId } = quoteSummary;
  const {
    dealer: { dealerCode }
  } = appContext;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/parts/${quoteServicePartId}/dealercode/${dealerCode}/quote/${confirmationId}/updatePart`,
        method: "patch",
        data: { typeOfPurchase }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes to parts."
    );
  });
};

const deletePart = async (
  appContext,
  quoteSummary,
  quoteServicePartId,
  returnToInventory,
  locationId
) => {
  const { confirmationId } = quoteSummary;
  const {
    dealer: { dealerCode }
  } = appContext;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/parts/${quoteServicePartId}/dealercode/${dealerCode}/quote/${confirmationId}/deletePart`,
        method: "delete",
        params: {
          returnToInventory,
          returnLocation: locationId
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to delete part."
    );
  });
};
const commitPart = async (appContext, quoteSummary, quoteService) => {
  const {
    dealer: { dealerCode }
  } = appContext;
  const purchaseType = quoteService.purchaseType
    ? quoteService.purchaseType
    : "Stock";
  const params = {
    repairOrderNumber: quoteSummary.roNumber,
    serviceOrderId: quoteSummary.serviceOrderId
      ? quoteSummary.serviceOrderId
      : "",
    customerId:
      quoteSummary.customer.commonConsumerId || quoteSummary.customer.extId,
    vin: quoteSummary.vehicle.vin,
    repairOrderParts: [
      {
        partNumber: quoteService.oemPartNumber,
        manufacturerCode: quoteService.dtDmsPartCode,
        partSequenceNumber: quoteService.roPartNum,
        unitOfMeasure: quoteService.unitOfMeasure
          ? quoteService.unitOfMeasure
          : "nsCoxEA",
        quantity: quoteService.quantity,
        price: quoteService.partPrice,
        specialOrderPriorityTag: quoteService.specialOrderPriority
          ? quoteService.specialOrderPriority
          : purchaseType,
        procurementType: purchaseType.toUpperCase()
      }
    ]
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/service-orders`,
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/vnd.coxauto.v1+json"
        },
        data: params
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to create service orders"
    );
  });
};

/**
 * Permanently deletes any parts that have been removed in the UI.
 * @param {object} appContext The current app context.
 * @param {Array} updatedPartsList Updated list of parts, which does not contain the removed parts.
 * @param {object} currentService Service being edited, whose parts list still shows the removed parts.
 * @param {object} currentQuote The current Quote or RO.
 * @returns {Promise<Awaited<unknown>[]>} A Promise which will resolve with an array of completed deletePart API calls.
 *          If no calls were made (i.e. no parts were removed), the array will be empty.
 */
const deleteRemovedParts = async (
  appContext,
  updatedPartsList,
  currentService,
  currentQuote
) => {
  try {
    if (currentService?.parts?.length) {
      const removedParts = currentService?.parts?.filter(oldPart => {
        return updatedPartsList.every(
          p => p.quoteServicePartId !== oldPart.quoteServicePartId
        );
      });
      if (removedParts?.length) {
        const result = await Promise.all(
          removedParts?.map(async removedPart => {
            const returnToInventory = removedPart?.approver ? true : false;
            try {
              const deletedPart = await deletePart(
                appContext,
                currentQuote,
                removedPart?.quoteServicePartId,
                returnToInventory
              );
              return { success: true, deletedPart };
            } catch (deletePartErr) {
              if (deletePartErr?.httpStatus !== "NOT_FOUND") {
                logError(deletePartErr);
              }
              return {
                success: false,
                deletedPart: removedPart,
                error: deletePartErr
              };
            }
          })
        );
        return result;
      }
    }
  } catch (err) {
    console.error(err);
    logError(err);
  }
  return Promise.resolve([]);
};

export const getInventoryPartsLocations = ({ dealerCode }) => {
  const headers = {
    Accept: "application/json"
  };
  const restEndPoint = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/locations`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Inventory Parts Locations failed, please try again in a few minutes."
    );
  });
};

/**
 *
 * @param {float} price  eg: 68.53999999999999
 * @returns outpur eg: 68.54
 */
const trimPrice = price => {
  const newPrice = !price ? 0 : price;
  return Number(newPrice.toFixed(2));
};

export default {
  updateQuoteFromModifyParts,
  updateQuoteFromModifyPartsWithMenu,
  markPartAsApproved,
  updatePart,
  deletePart,
  commitPart,
  deleteRemovedParts,
  getInventoryPartsLocations
};
